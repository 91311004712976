import { Canvas } from "@react-three/fiber";
import { Loader, MapControls } from "@react-three/drei";

import Level from './components/Level'
import Sudo from "./components/Sudo";
import Pyramid from "./components/Pyramid";
import Camera from "./components/Camera";
import Cactus from "./components/Cactus";
import Icon from "./components/Icon";

export default function App() {
  return (
    <>
      <Canvas
        flat
        dpr={[1, 2]}
        camera={{ position: [1, 1, 1], zoom: 150, near: -10, far: 10 }}
        orthographic
      >
        <color attach="background" args={["#e0b7ff"]} />
        <ambientLight />
        <Level />
        <Sudo />
        <Camera />
        <Cactus />
        <Icon />
        <Pyramid />
        <gridHelper args={[10, 10, `white`, `gray`]} />
        <MapControls minZoom={100} maxZoom={300} enableRotate={false} />
      </Canvas>
      <Loader />
    </>
  );
}
